import axios from "axios";
import {BrandEntity, ChannelEntity, ProductCategoryEntity, VideoEntity} from "./types";
import {useMutation, useQuery} from "react-query";
import {VideoSearchParams} from "../page/VideoList";
import {queryClient} from "../index";


export const apiGetVideo = async (videoId: number) => {
  const res = await axios.get<VideoEntity>(`/admin/v1/videos/${videoId}`)
  return res.data
}

export const useGetVideo = (videoId: number) => {
  return useQuery(
    [`/admin/v1/videos/${videoId}`],
    () => apiGetVideo(videoId)
  )
}

export interface BrandResponse {
  brandId: number
  status: string
  brandName: string
  categoryCode: string
  logoUrl: string
  createdAt: Date
  updatedAt: Date
}

const apiGetBrands = async (params: { status?: string }) => {
  const res = await axios.get<{ brands: BrandResponse[] }>(`/admin/v1/brands`, { params })
  return res.data
}

export const useBrands = (params: { status?: string }) => {
  return useQuery(
    [`/admin/v1/brands`, { params }],
    () => apiGetBrands(params),
    {
      keepPreviousData: true
    }
  )
}

interface PutBrandResponse {
  brandId: number
}

const apiPutBrand = async (payload: { brandId?: number, brandName: string, logoUrl: string, categoryCode?: string, }) => {
  const res = await axios.put<PutBrandResponse>(`/admin/v1/brands`, payload)
  return res.data
}

export const usePostBrand = () => {
  return useMutation({
    mutationFn: apiPutBrand,
    onSuccess: () => {
      queryClient.invalidateQueries(`/admin/v1/brands`)
    }
  })
}

export interface CategoryResponse {
  categoryId: number|undefined,
  status: string,
  parentId: number,
  depth: number,
  categoryCode: string
  name: string
  seq: number,
}

const apiGetCategories = async () => {
  const res = await axios.get<{ categories: CategoryResponse[] }>(`/admin/v1/categories`)
  return res.data
}

export const useCategories = () => {
  return useQuery(
    [`/admin/v1/categories`],
    apiGetCategories
  )
}

export interface VideoProduct {
  brandId?: number
  productName?: string
  adType: string
  categoryCode?: string
}

export const apiPutVideoProduct = async (params: { videoId: number, payload: VideoProduct }) => {
  const res = await axios.put<{ id: number }>(`/admin/v1/videos/${params.videoId}/products`, params.payload)
  return res.data
}

export const usePutVideoProduct = () => {
  return useMutation(
    apiPutVideoProduct,
  )
}

interface PageWrap<T> {
  items: T[],
  totalCount: number,
  totalPage: number
}

export const apiGetVideos = async (params: VideoSearchParams, page: number) => {
  const payload = { ...params, page }
  const res = await axios.get<{ items: VideoFull[], totalCount: number, totalPage: number }>(`/admin/v1/videos`, { params: payload })
  return res.data
}

export const apiGetAIPPLInfos = async (params: object) => {
  const res = await axios.get<{ items: AIPPLInfo[], totalCount: number, totalPage: number }>(`/admin/v1/ai-ppl-info`, { params: params })
  return res.data
}

export interface VideoFull {
  video: VideoEntity
  brand?: BrandEntity
  category: ProductCategoryEntity
  channel: ChannelEntity
}

export interface AIPPLInfo {
  id: number
  status: string // AssistantPplStatus,
  videoId: number // Long,
  channelId: number // Long,
  viewCount: number // Long,
  publishedAt: Date // LocalDateTime?,
  stated: boolean // Boolean,
  stateMessage: string // String,
  otherProductNames: string // String,
  brandName: string // String,
  category: string // String,
  categoryCode: string // String,
  productName: string // String,
  videoKey: string
  videoDescription: string
  channelName: string
}

export const useGetVideos = (params: VideoSearchParams|undefined, page: number) => {
  return useQuery(
    [`/admin/v1/videos,`, params, page],
    () => apiGetVideos(params!!, page),
    { enabled: !!params }
  )
}

interface PutCategoryRequest {
  parentId: number
  depth: number
  categoryCode: string
  name: string
  seq: number
}

const apiPutCategory = async (params: PutCategoryRequest) => {
  const res = await axios.put(`/admin/v1/categories`, params)
  return res.data
}

export const usePutCategory = () => {
  return useMutation(
    apiPutCategory,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`/admin/v1/categories`)
      }
    }
  )
}

export interface GetChannelRequest {
  channelKey: string,
  category: string,
  sort: string,
}

const apiGetChannels = async (params: GetChannelRequest, page: number) => {
  const payload = {
    ...params,
    page,
  }
  const res = await axios.get<PageWrap<ChannelEntity>>(`/admin/v1/channels`, { params: payload })
  return res.data
}

export const useGetChannels = (params: GetChannelRequest, page: number) => {
  return useQuery(
    [`/admin/v1/channels,`, params, page],
    () => apiGetChannels(params, page),
  )
}

export interface ChannelDetailResponse {
  channel: ChannelEntity,
  channelAudience?: ChannelAudienceGroup
}

export const apiGetChannelDetail = async (channelId: number) => {
  const res = await axios.get<ChannelDetailResponse>(`/admin/v1/channels/${channelId}`)
  return res.data
}

export const useGetChannelDetail = (channelId: number) => {
  return useQuery(
    [`/admin/v1/channels`, channelId],
    () => apiGetChannelDetail(channelId),
  )
}

export const apiGetExpectAudience = async (payload: OrgAudience) => {
  const res = await axios.post<ExpectAudience>(`/admin/v1/channels/audience/expect`, payload)
  return res.data
}

export const useGetExpectAudience = (payload: OrgAudience) => {
  return useQuery(
    [`/admin/v1/channels/audience/expect`, payload],
    () => apiGetExpectAudience(payload),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  )
}

export interface OrgAudience {
  org13?: string
  org18?: string
  org25?: string
  org35?: string
  org45?: string
  org55?: string
  org65?: string
  orgMale?: string
  orgFemale?: string
}

export interface ExpectAudience {
  male10?: string
  male20?: string
  male30?: string
  male40?: string
  male50?: string
  male60?: string
  female10?: string
  female20?: string
  female30?: string
  female40?: string
  female50?: string
  female60?: string
}

export interface PutChannelAudienceRequest extends ChannelAudienceGroup {
  channelId: string,
}

export interface ChannelAudienceGroup {
  original?: OrgAudience
  expect?: ExpectAudience
}



export const apiPutChannelAudience = async (payload: PutChannelAudienceRequest) => {
  const res = await axios.put(`/admin/v1/channels/${payload.channelId}/audience`, payload)
  return res.data
}

export const usePutChannelAudience = () => {
  return useMutation(
    apiPutChannelAudience,
  )
}

export const apiSignIn = async (payload : { email: string, password: string }) => {
  const res = await axios.post(`/v1/signin`, payload)
  return res.data
}

export const apiUpdatePPLInfoWithAI = async (payload: UpdatePPLInfoRequest) => {
  const res = await axios.post(`/admin/v1/ai-ppl-info`, payload)
  return res.data
}

interface UpdatePPLInfoRequest {
  id: number
  status?: string
  newBrand?: boolean
  brandId?: number
  brandName?: string
  categoryCode?: string
  productName?: string
  adType?: string
}

//@PostMapping("/admin/v1/channels")
export const apiPostChannel = async (channelKey: string) => {
  const payload = { channelKey }
  const res = await axios.post(`/admin/v1/channels`, payload)
  return res.data
}