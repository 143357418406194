import {
  EuiBasicTableColumn,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiPageBody,
  EuiSpacer
} from "@elastic/eui";
import {BrandResponse, CategoryResponse, useBrands, useCategories} from "../../api/apis";
import {EuiSearchBarProps} from "@elastic/eui/src/components/search_bar/search_bar";
import AddNewBrandModal, {AddNewBrandInitialData} from "../../components/AddNewBrandModal";
import {useMemo, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {Criteria} from "@elastic/eui/src/components/basic_table/basic_table";
import _ from "lodash"

const BrandList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status") as string | undefined
  const { data: brands } = useBrands({ status })
  const [ modifyData, setModifyData ] = useState<AddNewBrandInitialData|undefined>()
  const { data: categories } = useCategories()
  const [ openAllList, setOpenAllList ] = useState<AddNewBrandInitialData[]>([])

  const categoryByCode = useMemo(() => {
    if (!categories) {
      return {}
    }

    const map = {} as { [key: string]: CategoryResponse }
    categories!!.categories?.forEach((item) => {
      map[item.categoryCode || ''] = item
    })
    return map
  }, [categories])

  const sorting = {
    sort: {
      field: 'dateOfBirth',
      direction: 'desc' as const,
    },
  };

  const search: EuiSearchBarProps = {
    onChange: undefined,
    box: {
      incremental: true,
    },
  };
  const currPage = Number(searchParams.get("page") || '1')

  const pagination = {
    pageIndex: currPage - 1,
    pageSize: 20 || 0, // 페이지당 몇개
    totalItemCount: brands?.brands.length || 0, // 전체 갯수 몇개
    showPerPageOptions: false,
  };

  const onTableChange = ({ page, sort }: Criteria<BrandResponse>) => {
    let nextParams = { page: `${currPage}`, sort: '' }

    if (page) {
      const { index: pageIndex, size: pageSize } = page;
      nextParams.page = `${pageIndex + 1}`
    }

    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      nextParams.sort = sortField
    }

    setSearchParams(nextParams)
  };

  const columns: Array<EuiBasicTableColumn<BrandResponse>> = [
    {
      field: 'brandName',
      name: '브랜드명',
      sortable: true,
      truncateText: true,
      render: (_, item) => {
        return (
          <>
            <EuiFlexGroup>
              <EuiFlexItem
                grow={false}
              >
                <img
                  src={item.logoUrl}
                  style={{width: "32px", height: "32px", background: "black"}}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                {item.brandName}
              </EuiFlexItem>
            </EuiFlexGroup>
          </>
        );
      }
    },
    {
      field: 'categoryCode',
      name: '카테고리',
      width: "120px",
      render: (_, item) => {
        return (
          <>
            {categoryByCode[item.categoryCode]?.name}
          </>
        );
      }
    },
    {
      field: 'brandName',
      name: '',
      sortable: false,
      truncateText: false,
      width: "120px",
      render: (_, item) => {
        return (
          <>
            <EuiButton
              size="s"
              color="text"
              onClick={() => {
                setModifyData({
                  brandId: item.brandId,
                  brandName: item.brandName,
                  categoryCode: item.categoryCode,
                  status: item.status,
                  logoUrl: item.logoUrl }
                )
              }}
            >
              수정
            </EuiButton>
          </>
        )
      }
    },
  ];

  const onCloseModal = (brandId?: number) => {
    setModifyData(undefined)

    if (openAllList.length) {
      const nextOpenAllList = _.drop(openAllList, 1)
      setOpenAllList(nextOpenAllList)

      setTimeout(() => {
        const nextModifyData = nextOpenAllList[0]
        setModifyData(nextModifyData)
      }, 100)
    }
  }

  return (
    <EuiPageBody
      paddingSize="xl"
      panelled
    >
      <EuiFlexGroup
        justifyContent="flexEnd"
        gutterSize='s'
      >
        <EuiFlexItem
          grow={false}
        >
          <EuiButton
            size="s"
            color='text'
            onClick={() => {
              setModifyData({})
            }}
          >
            추가
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem
          grow={false}
        >
          <EuiButton
            size="s"
            color='text'
            onClick={() => {
              const skip = pagination.pageIndex * pagination.pageSize
              const thisItems = _.chain(brands?.brands).drop(skip).take(pagination.pageSize).value()

              setOpenAllList(thisItems)
              setModifyData(thisItems[0])
            }}
          >
            전체 수정
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="xl" />
      <div
        style={{ width: 800 }}
      >
        <EuiInMemoryTable
          items={brands?.brands || []}
          columns={columns}
          pagination={pagination}
          search={search}
          sorting={sorting}
          onChange={onTableChange}
        />
      </div>
      { !!modifyData && (
        <AddNewBrandModal
          initialData={modifyData}
          onClose={onCloseModal}
        />
      )}
    </EuiPageBody>
  )
}

export default BrandList